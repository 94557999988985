import sanitize from "sanitize-html";
import { Author } from "./auther.model";
import { BlogList } from "./blogList.model";
import { calculateEstimatedReadTime } from "./utils";

export class Blog {
  constructor({
    title,
    published,
    author = {},
    thumbnail,
    content,
    labels,
    images,
    id,
  }) {
    this.title = title;
    this.published = published;
    this.author = new Author(author);
    this.thumbnail = thumbnail;
    this.content = content;
    this.labels = labels;
    this.images = images;
    this.id = id;
  }

  get displayContent() {
    const dirtyHtml = this.content;
    const cleanHtml = sanitize(dirtyHtml, {
      allowedTags: [],
    }).trim();
    return cleanHtml.length > 150
      ? cleanHtml.substring(0, 150) + "..."
      : cleanHtml;
  }

  get displayLabel() {
    return this.labels?.[0];
  }

  get displayImage() {
    return this.images?.[0]?.url;
  }

  get formattedDate() {
    if (!this.published) {
      return "";
    }
    let newYear = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      new Date(this.published)
    );
    let newMonth = new Intl.DateTimeFormat("en", { month: "short" }).format(
      new Date(this.published)
    );
    let newDay = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
      new Date(this.published)
    );
    return `${newDay} ${newMonth} ${newYear}`;
  }

  get readTime() {
    return calculateEstimatedReadTime(this.content);
  }

  isEqual(id) {
    return this.id === id;
  }

  static fromList(blogs = []) {
    return new BlogList(blogs.map((blog) => new Blog(blog)));
  }
}
