import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBlogById } from "../store/action/blogAction";
import { blogSelector } from "../store/selector/blogSelector";
import "../styles/blogDetails.scss";
import BackButton from "./BackButton";
import BlogDetailsSkeleton from "./BlogDetailsSkeleton";

const BlogDetails = () => {
  const dispatch = useDispatch();
  const { postId } = useParams();
  const blog = useSelector(blogSelector);
  const { loading } = useSelector((store) => store.blog);

  useEffect(() => {
    dispatch(fetchBlogById(postId));
    window.scrollTo(0, 0);
  }, [postId]);

  return (
    <>
      {!loading ? (
        <section className="blogDetailsContainer">
          <div className="icon-blogHeading">
          <BackButton /> 
          <h1 className="blog_title">{blog?.title}</h1>
          </div>
         
          <div className="blog_author">
            <p>By {blog?.author.displayName}</p>
            <span> · </span>
            <p>{blog?.formattedDate}</p>
            <span> · </span>
            <p>{`${blog.readTime} min read`}</p>
          </div>
          <div
            className="blog_content"
            dangerouslySetInnerHTML={{ __html: blog?.content }}
          />
        </section>
      ) : (
        <BlogDetailsSkeleton />
      )}
    </>
  );
};

export default BlogDetails;
