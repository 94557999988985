import Skeleton from "./Skeleton";
import "../styles/heroBlogSkeleton.scss";
const HeroBlogSkeleton = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="herocard">
        <div className="herocard__body">
          <Skeleton type="text-1" />
          <Skeleton type="text-2" />
          <Skeleton type="text-3" />
          <Skeleton type="text-3" />
          <Skeleton type="text-4" />
        </div>
        <div className="herocard__head">
          <Skeleton type="thumbnail" />
        </div>
      </div>

      <div className="shimmer-wrapper">
        <div className="shimmer"></div>
      </div>
    </div>
  );
};
export default HeroBlogSkeleton;
