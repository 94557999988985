import Skeleton from "./Skeleton";
import "../styles/blogSkeleton.scss";

const BlogSkeleton = () => {
  return (
    <div className="blogSkeleton-wrapper">
      <div className="blogCard">
        <div className="blogCardHead">
          <Skeleton type="thumbnail" />
        </div>

        <div className="blogCardBody">
          <Skeleton type="text-1" />
          <Skeleton type="text-2" />
          <Skeleton type="text-3" />
          <Skeleton type="text-4" />
          <Skeleton type="text-4" />
          <Skeleton type="text-5" />
          <Skeleton type="text-6" />
        </div>
      </div>

    </div>
  );
};
export default BlogSkeleton;
