import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../styles/header.scss";
import "../styles/headerAnimation.scss";
import CodinovaLogo from "./CodinovaLogo";
import CodinovaTextLogo from "./CodinovaTextLogo";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [scrollToggle, setScrollToggle] = useState(false);
  const history = useLocation();

  window.addEventListener("scroll", () => {
    if (window.scrollY > 80) {
      setScrollToggle(true);
    } else {
      setScrollToggle(false);
    }
  });

  const toggleActive = () => {
    setToggle((previousState) => !previousState);
  };

  return (
    <header
      className={scrollToggle ? "site-header sticky-header" : "site-header"}
    >
      <section className="header-content">
        <a className="logo-link" href={process.env.REACT_APP_CODINOVA_URL}>
          <CodinovaTextLogo />
          <CodinovaLogo />
        </a>

        <nav
          className={toggle ? "is-active navbar" : "navbar"}
          onClick={toggleActive}
        >
          <NavLink
            id="insightsBtn"
            to="/blogs"
            className={() =>
              `insightsBtn  ${history.pathname.includes("/blogs") && "active"}`
            }
          >
            Insights
          </NavLink>
          <a
            id="careerBtn"
            href={`${process.env.REACT_APP_CODINOVA_URL}#career-section`}
          >
            Career
          </a>
          <a
            id="contactBtn"
            className="contact-btn"
            href={`${process.env.REACT_APP_CODINOVA_URL}#contact-section`}
          >
            Contact
          </a>
        </nav>

        <div
          className={toggle ? "is-active hamburger" : "hamburger"}
          onClick={toggleActive}
        >
          <img id="hamburger-icon" src="/assests/hamburger.png" alt="" />
          <img id="close-icon" src="/assests/close.png" alt="" />
        </div>
      </section>
    </header>
  );
};

export default Header;
