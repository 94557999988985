import React from "react";
import { Link } from "react-router-dom";
import "../styles/heroblog.scss";

const HeroBlog = ({ heroBlog }) => {
  return (
    <div className="heroblog-card">
      <div className="herocard__head">
        <Link to={`/${heroBlog?.id}`}>
          {heroBlog.displayImage ? (
            <img src={heroBlog?.displayImage} className="card__image" />
          ) : (
            <div className="no-image-blog"></div>
          )}
        </Link>
      </div>
      <div className="herocard__body">
        <div className="blog__tag">
          <span className="tag">{heroBlog?.displayLabel}</span>
          <span>{`${heroBlog?.readTime} min read`}</span>
        </div>
        <Link to={`/${heroBlog?.id}`}>
          <h4>{heroBlog?.title}</h4>
        </Link>
        <p>{heroBlog?.displayContent}</p>
        <div className="user">
          <div>Written By {heroBlog?.author.displayName}</div>
          <small>{heroBlog?.formattedDate}</small>
        </div>
      </div>
    </div>
  );
};

export default HeroBlog;
