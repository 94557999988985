import React from "react";
import "../styles/footer.scss";
const Footer = () => {
  return (
    <footer className="site-footer" id="siteFooter">
      © 2023 CODINOVA TECHNOLOGIES PVT. LTD.
      <br />
      All rights reserved.
    </footer>
  );
};

export default Footer;
