import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blog: {},
  loading: false,
};

const blogsSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlog(state, { payload }) {
      state.blog = { ...payload };
    },
    setBlogLoading(state, { payload }) {
      state.loading = payload;
    },
  },
});

export const { setBlog, setBlogLoading } = blogsSlice.actions;
export default blogsSlice.reducer;
