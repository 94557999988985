import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogs: [],
  featuredBlog: {},
  loading: false,
  blogsLoading: false,
  nextPageToken: "",
};

const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    setAll(state, { payload }) {
      state.blogs = [...state.blogs, ...payload];
    },

    setFeaturedBlog(state, { payload }) {
      state.featuredBlog = { ...payload };
    },

    setLoading(state, { payload }) {
      state.loading = payload;
    },

    setBlogsLoading(state, { payload }) {
      state.blogsLoading = payload;
    },

    setNextPageToken(state, { payload }) {
      state.nextPageToken = payload;
    },
  },
});

export const {
  setAll,
  setLoading,
  setFeaturedBlog,
  setNextPageToken,
  setBlogsLoading,
} = blogsSlice.actions;
export default blogsSlice.reducer;
