import React from "react";
import "../styles/loadMoreButton.scss";

const LoadMoreButton = ({ handleNextPage }) => {
  return (
    <div className="btn-container">
      <button onClick={handleNextPage}>LOAD MORE</button>
    </div>
  );
};

export default LoadMoreButton;
