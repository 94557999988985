import { get } from "./utils";
import {
  setAll,
  setBlogsLoading,
  setFeaturedBlog,
  setLoading,
  setNextPageToken,
} from "../slices/blogsSlice";
import { setBlog, setBlogLoading } from "../slices/blogSlice";

const baseUrl = `/api`;

export const fetchAllBlogs = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const fetchFeaturedBlog = get(`${baseUrl}/featured-blogs`);
    const fetchBlogs = get(`${baseUrl}/blogs`)
    const [{ data: featuredBlogResult }, { data: blogsResult }] = await Promise.all([fetchFeaturedBlog, fetchBlogs]);

    dispatch(setAll(blogsResult.blogs));
    dispatch(setFeaturedBlog(featuredBlogResult.featuredBlog));
    dispatch(setNextPageToken(blogsResult.nextPageToken));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchBlogById = (postId) => async (dispatch) => {
  dispatch(setBlogLoading(true));
  try {
    const {data: result} = await get(
      `${baseUrl}/blogs/${postId}`
    );
    dispatch(setBlog(result.blog));
  } catch (error) {
    alert(error.message);
  } finally {
    dispatch(setBlogLoading(false));
  }
};

export const fetchNextPageBlogs = (pageToken) => async (dispatch) => {
  dispatch(setBlogsLoading(true));
  try {
    const {data: blogsResult} = await get(
      `${baseUrl}/blogs?pageToken=${pageToken}`
    );
    dispatch(setAll(blogsResult.blogs));
    dispatch(setNextPageToken(blogsResult.nextPageToken));
  } catch (error) {
    alert(error.message);
  } finally {
    dispatch(setBlogsLoading(false));
  }
};
