export class BlogList extends Array {
  constructor(blogs = []) {
    if (blogs?.length) {
      super(...blogs);
    } else {
      super();
    }
  }

  get uniqueBlogs() {
    return this.filter(
      (blog, index, self) => index === self.findIndex((b) => b.id === blog.id)
    );
  }

  findById(blogId) {
    return this.find((blog) => blog.isEqual(blogId));
  }
}
