import React from "react";
import Blog from "./Blog";
import "../styles/blogcard.scss";
import BlogSkeleton from "./BlogSkeleton";
import LoadMoreButton from "./LoadMoreButton";

const BlogList = ({
  blogs,
  blogsLoading,
  itemNumber,
  handleNextPage,
  nextPageToken,
}) => {
  return (
    <>
      {blogs.length && (
        <div className="blogList">
          {React.Children.toArray(blogs.map((blog) => <Blog blog={blog} />))}
        </div>
      )}
      {blogsLoading && (
        <div className="blogList">
          {React.Children.toArray(
            new Array(itemNumber).fill(itemNumber).map(() => <BlogSkeleton />)
          )}
        </div>
      )}
      {nextPageToken && <LoadMoreButton handleNextPage={handleNextPage} />}
    </>
  );
};

export default BlogList;
