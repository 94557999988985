import Skeleton from "./Skeleton";
import "../styles/blogDetailsSkeleton.scss";
const BlogDetailsSkeleton = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="blogDetails">
        <Skeleton type="text-1" />
        <Skeleton type="text-1" />
        <Skeleton type="text-2" />
        <Skeleton type="text-3" />
        <Skeleton type="text-3" />
        <Skeleton type="text-3" />
        <Skeleton type="text-4" />
      </div>
    </div>
  );
};
export default BlogDetailsSkeleton;
