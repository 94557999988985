import React, { useEffect } from "react";
import BlogList from "./BlogList";
import HeroBlog from "./HeroBlog";
import { useDispatch, useSelector } from "react-redux";
import HeroBlogSkeleton from "./HeroBlogSkeleton";
import "../styles/content.scss";
import BlogSkeleton from "./BlogSkeleton";
import {
  blogsSelector,
  featuredBlogSelector,
} from "../store/selector/blogSelector";
import { fetchAllBlogs, fetchNextPageBlogs } from "../store/action/blogAction";

const Content = () => {
  const dispatch = useDispatch();
  const blogs = useSelector(blogsSelector);
  const featuredBlog = useSelector(featuredBlogSelector);
  const { loading, nextPageToken, blogsLoading } = useSelector(
    (store) => store.blogs
  );

  useEffect(() => {
    if (!blogs.length && !nextPageToken) {
      dispatch(fetchAllBlogs());
    }
  }, []);

  const handleNextPage = () => {
    dispatch(fetchNextPageBlogs(nextPageToken));
  };

  const heroBlog = featuredBlog;
  const itemNumber = parseInt(process.env.REACT_APP_SKELETON_ITEM_NUMBER);

  return (
    <>
      {!loading ? (
        <>
          <HeroBlog heroBlog={heroBlog} />
          <div className="latest-article">Latest Articles</div>
          <BlogList
            blogs={blogs.uniqueBlogs}
            blogsLoading={blogsLoading}
            itemNumber={itemNumber}
            handleNextPage={handleNextPage}
            nextPageToken={nextPageToken}
          />
        </>
      ) : (
        <>
          <HeroBlogSkeleton />
          <div className="blogList">
            {React.Children.toArray(
              new Array(itemNumber).fill(itemNumber).map(() => <BlogSkeleton />)
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Content;
