import React from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Navigate, Route, Routes } from "react-router-dom";
import BlogDetails from "./components/BlogDetails";
import Content from "./components/Content";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path=":postId" element={<BlogDetails />} />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
