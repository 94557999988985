import React from "react";
const CodinovaTextLogo = () => {
  return (
    <svg
      className="codinova-text-logo"
      viewBox="0 0 456 146"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://boxy-svg.com"
    >
      <g
        transform="matrix(0.408369, 0, 0, 0.408369, -248.902908, -84.508453)"
        bbox="0.499394 0.5"
      >
        <path d="M783.1,314.1c1.6,1,3.1,2.6,4.8,3c8.3,1.9,14.1,7.5,14.7,16c0.7,9.8,0.2,19.6,0.9,29.4c0.7,9.4,2.8,18.5,11.5,24.1 c2.4,1.6,5.4,2.4,8.1,3.5c0,4,0,8,0,12c-3.1,1.6-6.6,2.6-9.1,4.8c-6.6,5.5-9.6,12.9-10,21.5c-0.5,11.3-0.7,22.6-2,33.8 c-0.9,7.7-6.6,12.4-13.9,13.9c-5.8,1.2-11.9,1.1-17.8,1.2c-8.9,0.2-17.9,0.1-26.8,0.2c-0.8,0-1.7,0.2-2.5,0.2 c-7.2,0.2-8.2-0.7-9-8c-2.2,0-4.4,0-7,0c-0.1,4.2,0.1,8.5-6.2,8.1c-5.8-0.4-3.5-4.9-4.7-8.1c-2.2,0-4.4,0-6.6,0 c-1.4,8-1.4,8.1-9.8,8c-12.8-0.2-25.6-0.4-38.4-0.6c-10.3-0.2-19.4-7.8-20.1-19.4c-0.6-9.3-0.5-18.6-0.9-27.9 c-0.4-8.6-2.9-16.5-9.6-22.3c-2.8-2.4-6.2-4.2-9.4-6.2c0-3.3,0-6.7,0-10c0.7-0.5,1.3-1.1,2-1.4c14.5-5.4,17.4-17.4,17.7-30.9 c0.2-8.2-0.1-16.3,0.6-24.4c0.8-9.5,6.5-15.3,15.6-17.4c1.5-0.4,2.8-1.8,4.1-2.8C700.4,314.1,741.7,314.1,783.1,314.1z M821.2,402 c0-3.2,0.2-5.8-0.1-8.2c-0.1-1.2-0.7-3-1.5-3.3c-7.4-2.3-12-7-14.8-14.3c-2.2-5.6-4-11-3.8-17.1c0.2-7.7,0.2-15.3,0-23 c-0.2-6.4-1.9-12.7-8.1-15.3c-6.5-2.6-13.6-5-20.5-5.1c-31.5-0.5-63-0.3-94.5-0.2c-7,0-14.4-1.8-20.6,3.4 c-0.5,0.4-1.6,0.2-2.4,0.2c-7.3-0.1-10.7,4.8-12.6,10.6c-1.1,3.5-0.9,7.5-1.2,11.3c-0.8,10.9,1.6,22-2.8,32.9 c-2.9,7.3-6.5,13.3-13.9,16.1c-3.7,1.4-3.2,4.1-3.3,6.8c-0.1,2.6,0.2,4.6,3.1,6c10.3,4.7,14.5,14,15.7,24.3 c1.2,10.2,0.9,20.5,1.2,30.8c0.2,7.4,5.4,14.5,12.8,16.2c4.5,1,9.1,1.7,13.7,1.9c10.2,0.3,20.3,0.2,30.5,0c2.3,0,4.7-0.7,7.4-1.2 c-0.6-2-1.3-3.2-1.1-4.4c0.1-1,1.2-2.7,1.8-2.7c3.3-0.1,6.5,0.3,9.3,0.5c1.9,2.8-1.6,7.9,4,7.7c5.4-0.2,1.4-5.1,3.4-7.5 c3.5,0,7.4,0,11.2,0c0.2,2.5,0.3,4.5,0.4,6.7c7.3,0,14.4,0,21.5,0c9.2,0,18.5,0.1,27.7-0.1c8.9-0.2,15.5-5.2,16.5-13.8 c1.3-11.1,1.9-22.4,1.9-33.6c0-6.4,2.7-11.7,6-16.3C810.9,406.9,814.3,401.9,821.2,402z" />
        <path
          className="st0"
          d="M705.1,549.1c-2.6-2.6-5.6-5.1-7.8-8c-1.6-2.1-1.2-4.1,2.2-4.1c15.1,0,30.1,0,45.6,0c1.4,6.5-4.7,8.2-7,12.1 C727.1,549.1,716.1,549.1,705.1,549.1z"
          style={{
            fill: "rgb(79, 79, 79)",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: 10,
          }}
        />
        <path d="M721.4,485.3c11.1,0,22.3-0.1,33.4,0.1c2,0,4.2,0.7,6,1.7c3.6,2,4,4.7,0.6,6.9c-4.7,3-4.1,6.4-2.6,10.7 c0.6,1.6,0.4,3.8-0.2,5.5c-1.5,4.2-3.6,7.9,0.4,12.1c2.5,2.7-0.2,6.9-4.5,7.1c-4.3,0.2-8.6,0.1-13,0.1c-17.1,0-34.3,0.1-51.4-0.1 c-2.5,0-5.4-1.2-7.3-2.8c-0.7-0.6,1.7-4,1.7-6.2c0.1-3.2-0.7-6.3-1-9.5c-0.2-2.1-0.9-4.5-0.4-6.4c1.2-4.1,2.1-7.6-2.5-10.3 c-1.2-0.7-2.3-4.1-2-4.4c2.2-1.8,4.7-4.1,7.2-4.3c8.1-0.5,16.3-0.2,24.4-0.2C714.1,485.3,717.7,485.3,721.4,485.3 C721.4,485.3,721.4,485.3,721.4,485.3z M679.8,489.6c0.6,1.5,0.7,2.1,1,2.4c6.5,5.5,6.4,5.6,4,13.5c-0.4,1.4,0.7,3.2,0.9,4.8 c0.4,3.9,0.7,7.9,0.7,11.8c0,1.4-1.2,2.8-1.9,4.2c1.5,0.5,3,1.3,4.5,1.4c21.3,0.1,42.7,0.1,64,0.1c1,0,2.7,0.2,2.8-0.2 c0.7-1.5,1.6-3.9,1-4.7c-3-3.6-3.3-8.5-0.9-12.4c1-1.6,1.3-4.2,0.8-6c-1.9-5.7-1.2-8.5,3.6-11.7c0.7-0.5,1.1-1.4,2.5-3.2 c-3.3-0.9-5.8-2.1-8.4-2.2c-22.3-0.2-44.6-0.2-67,0C685,487.4,682.5,488.8,679.8,489.6z" />
        <path
          id="bulbBg"
          className="st1"
          d="M821.2,402c-6.9-0.1-10.3,4.9-13.4,9.2c-3.3,4.7-5.9,9.9-6,16.3c0,11.2-0.6,22.5-1.9,33.6 c-1,8.6-7.5,13.6-16.5,13.8c-9.2,0.2-18.5,0.1-27.7,0.1c-7.1,0-14.2,0-21.5,0c-0.1-2.3-0.3-4.2-0.4-6.7c-3.8,0-7.7,0-11.2,0 c-2,2.4,2,7.4-3.4,7.5c-5.5,0.2-2-4.9-4-7.7c-2.8-0.2-6-0.6-9.3-0.5c-0.7,0-1.7,1.7-1.8,2.7c-0.1,1.2,0.6,2.5,1.1,4.4 c-2.7,0.5-5.1,1.2-7.4,1.2c-10.2,0.1-20.3,0.2-30.5,0c-4.6-0.1-9.2-0.9-13.7-1.9c-7.4-1.6-12.6-8.8-12.8-16.2 c-0.2-10.3,0-20.6-1.2-30.8c-1.2-10.3-5.5-19.6-15.7-24.3c-2.9-1.3-3.2-3.3-3.1-6c0.1-2.8-0.4-5.4,3.3-6.8 c7.4-2.8,11-8.8,13.9-16.1c4.4-10.9,2-21.9,2.8-32.9c0.3-3.8,0.1-7.7,1.2-11.3c1.8-5.8,5.2-10.8,12.6-10.6c0.8,0,1.9,0.2,2.4-0.2 c6.2-5.2,13.6-3.4,20.6-3.4c31.5-0.1,63-0.4,94.5,0.2c6.9,0.1,14.1,2.5,20.5,5.1c6.3,2.6,7.9,8.9,8.1,15.3c0.2,7.7,0.3,15.3,0,23 c-0.2,6.1,1.6,11.5,3.8,17.1c2.8,7.3,7.3,12.1,14.8,14.3c0.8,0.2,1.4,2.1,1.5,3.3C821.4,396.3,821.2,398.8,821.2,402z"
          style={{ fill: "rgb(255, 255, 255)" }}
        />
        <linearGradient
          id="gradient-1"
          gradientUnits="userSpaceOnUse"
          x1="679.8027"
          y1="507.5222"
          x2="762.8004"
          y2="507.5222"
        >
          <stop offset={1} style={{ stopColor: "#8A8A8A" }} />
          <stop offset={1} style={{ stopColor: "#000000" }} />
        </linearGradient>
        <path
          className="st2"
          d="M679.8,489.6c2.7-0.8,5.2-2.2,7.6-2.2c22.3-0.2,44.6-0.2,67,0c2.5,0,5.1,1.3,8.4,2.2 c-1.4,1.8-1.8,2.7-2.5,3.2c-4.8,3.2-5.4,6-3.6,11.7c0.6,1.8,0.2,4.4-0.8,6c-2.4,4-2.1,8.8,0.9,12.4c0.7,0.8-0.3,3.2-1,4.7 c-0.2,0.4-1.9,0.2-2.8,0.2c-21.3,0-42.7,0-64-0.1c-1.5,0-3-0.9-4.5-1.4c0.7-1.4,1.9-2.8,1.9-4.2c0-3.9-0.3-7.9-0.7-11.8 c-0.1-1.6-1.3-3.4-0.9-4.8c2.4-7.9,2.5-8-4-13.5C680.5,491.7,680.4,491.1,679.8,489.6z"
          style={{ fill: "url(#gradient-1)" }}
        />
        <path
          className="st3"
          d="M681.1,453.3c-0.1-1.9-0.2-3.7-0.4-5.6c-0.6-4.3-2.6-7.7-6.2-10.1c-5-3.5-6.1-7.5-4.2-12.5 c0.9-2.3,2.9-4.5,4.8-6.2c4.6-4.2,7-9.1,6.4-15.4c-0.2-2-0.5-4.1-0.1-6c0.8-3.4,3.2-5.6,6.5-6.6c1.4-0.4,3.1,0,4.6,0.2 c6.1,0.8,11.6-0.5,15.9-5.1c1.9-2,3.5-4.4,5.4-6.5c0.4-0.5,1.2-0.9,1.8-0.9c2.4-0.1,4.8-0.1,7.2,0c0.7,0,1.6,0.5,2.2,1.1 c2.3,2.4,4.4,4.9,6.7,7.3c3.7,3.9,8.4,5.1,13.4,4.2c4.8-0.9,8.4,0.8,11.6,4c1.6,1.6,2.3,3.7,1.8,6.1c-0.8,3.6-0.7,7.3,0.6,10.9 c1.8,4.9,5.4,8.1,9.8,10.6c1,0.6,1.4,1.1,1.4,2.2c-0.1,2.3-0.1,4.7,0,7c0,1.2-0.6,1.8-1.5,2.5c-2.7,2.2-5.7,4.1-7.9,7 c-2,2.6-2.7,5.5-2.8,8.6c-0.1,1.9,0.2,3.7,0.3,5.6c0.6,6.8-6.7,12.3-13.1,11.3c-5.2-0.8-10.3-0.5-14.5,3.2 c-0.9,0.8-1.9,1.8-2.6,2.8c-1,1.4-1.9,3-2.9,4.5c-0.3,0.4-0.9,0.7-1.3,0.8c-2.9,0.1-5.8,0.2-8.7,0c-0.9-0.1-1.9-1-2.6-1.7 c-1.4-1.5-2.6-3.2-3.9-4.8c-4-4.9-9.4-6.1-15.3-4.8c-6.4,1.4-12.7-3.5-12.5-10.2c0-1,0-2.1,0-3.1 C681,453.3,681.1,453.3,681.1,453.3z M740.1,416.1L740.1,416.1c1,2.2,1.9,4.5,3,6.7c1.6,3.1,1.3,6.4,0.4,9.5 c-1.5,4.9-5.1,8.6-8.3,12.5c-1.8,2.2-2.8,4.5-3,7.2c-0.1,1.7,0.1,3.5,1.7,4.5c1.5,1,3.1,0.4,4.4-0.5c2.1-1.4,4.3-2.8,6.1-4.6 c3.7-3.7,6.3-8.1,8.1-13c2-5.3,2.4-10.9,1.2-16.5c-1-4.7-3.3-8.9-6.2-12.8c-2.5-3.4-5.5-6.2-8.9-8.7c-8.2-5.8-17.2-5.8-26.5-4.4 c-5.6,0.9-10.3,3.6-14.8,7c-4.7,3.5-8.1,7.9-9.8,13.3c-3.1,9.4-3,18.8,1.7,27.9c2.5,4.9,6.1,8.7,10.7,11.6 c2.8,1.8,5.6,1.1,7.4-1.6c1.6-2.5,0.8-5.5-1.8-7.3c-4-2.7-7.3-5.9-8-11c0-0.3-0.1-0.7-0.2-1c-1.6-4.7-1.5-9.3,0-14 c1.7-5.4,4.9-9.2,9.7-12c3.7-2.2,7.7-3.5,12-3.9c8-0.8,14,2.9,19,8.7C738.4,414.6,739.3,415.3,740.1,416.1z M713.8,442.7 c0,4.2-0.1,8.4,0.1,12.6c0.1,1.6,0.7,3.3,1.4,4.7c1.3,2.3,4.2,2.8,6.5,1.6c2.3-1.2,3.1-3.5,3.1-5.7c0.2-8.4,0.1-16.8,0.2-25.2 c0-3.3-1.5-5.6-4.8-6.7c-1.9-0.7-3.6-0.5-4.7,1.1c-0.9,1.4-1.7,3.1-1.8,4.7C713.7,434.2,713.8,438.5,713.8,442.7z"
          style={{
            fill: "rgb(34, 94, 150)",
            stroke: "rgb(0, 0, 0)",
            strokeMiterlimit: 10,
          }}
        />
        <g id="codinovaLogoLayer1">
          <text
            transform="matrix(1.0555 0 0 1 678.6917 356.8795)"
            className="st4 st5 st6"
            style={{
              whiteSpace: "pre",
              fill: "rgb(34, 94, 150)",
              fontFamily: '"Poppins"',
              fontWeight: 600,
              fontSize: "34px",
              lineHeight: "54.4px",
              perspectiveOrigin: "7.51562px 0px",
            }}
          >
            0
          </text>
        </g>
        <g id="codinovaLogoLayer2">
          <text
            transform="matrix(1.0555 0 0 1 728.9025 349.602)"
            className="st4 st5 st6"
            style={{
              whiteSpace: "pre",
              fill: "rgb(34, 94, 150)",
              fontFamily: '"Poppins"',
              fontWeight: 600,
              fontSize: "34px",
              lineHeight: "54.4px",
              perspectiveOrigin: "5.32812px 0px",
            }}
          >
            1
          </text>
        </g>
        <g id="codinovaLogoLayer3">
          <text
            transform="matrix(1.0555 0 0 1 698.9589 305.6478)"
            className="st4 st5 st6"
            style={{
              whiteSpace: "pre",
              fill: "rgb(34, 94, 150)",
              fontFamily: '"Poppins"',
              fontWeight: 600,
              fontSize: "34px",
              lineHeight: "54.4px",
              perspectiveOrigin: "7.51562px 0px",
            }}
          >
            0
          </text>
        </g>
        <g id="codinovaLogoLayer4">
          <text
            transform="matrix(1.0555 0 0 1 672.8813 321.12)"
            className="st4 st5 st6"
            style={{
              whiteSpace: "pre",
              fill: "rgb(34, 94, 150)",
              fontFamily: '"Poppins"',
              fontWeight: 600,
              fontSize: "34px",
              lineHeight: "54.4px",
              perspectiveOrigin: "5.32812px 0px",
            }}
          >
            1
          </text>
        </g>
        <g id="codinovaLogoLayer5">
          <text
            transform="matrix(1.0555 0 0 1 716.3007 248.7096)"
            className="st4 st5 st6"
            style={{
              whiteSpace: "pre",
              fill: "rgb(34, 94, 150)",
              fontFamily: '"Poppins"',
              fontWeight: 600,
              fontSize: "34px",
              lineHeight: "54.4px",
              perspectiveOrigin: "5.32812px 0px",
            }}
          >
            1
          </text>
        </g>
        <g id="codinovaLogoLayer6">
          <text
            transform="matrix(1.0555 0 0 1 685.4387 264.0362)"
            className="st4 st5 st6"
            style={{
              whiteSpace: "pre",
              fill: "rgb(34, 94, 150)",
              fontFamily: '"Poppins"',
              fontWeight: 600,
              fontSize: "34px",
              lineHeight: "54.4px",
              perspectiveOrigin: "7.51562px 0px",
            }}
          >
            0
          </text>
        </g>
        <text
          id="codinovaLogoLayer7"
          transform="matrix(1.0555, 0, 0, 1, 853.164, 479.962)"
          className="st4 st5 st7"
          style={{
            whiteSpace: "pre",
            fill: "rgb(34, 94, 150)",
            fontFamily: '"Poppins"',
            fontWeight: 600,
            fontSize: "149px",
            lineHeight: "320px",
            perspectiveOrigin: "398.594px 0px",
          }}
        >
          CODINOVA
        </text>
      </g>
    </svg>
  );
};

export default CodinovaTextLogo;
