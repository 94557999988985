import React, { useState } from "react";
import { Link } from "react-router-dom";

const Blog = ({ blog }) => {
  const [imageHasError, setImageError] = useState(false)
  const handleImageError = () => {
    setImageError(true)
  }
  
  return (
    <div className="card">
      <div className="card__head">
        <Link to={`/${blog?.id}`}>
          {blog.displayImage && !imageHasError ? (
            <img 
              onError={handleImageError}
              src={blog.displayImage}
              alt="card__image"
              className="card__image"
            />
          ) : (
            <div className="no-image-blog"></div>
          )}
        </Link>
      </div>

      <div className="card__body">
        <div className="blog__tag">
          <span className="tag">{blog.displayLabel}</span>
          <span>{`${blog.readTime} min read`}</span>
        </div>
        <div className="blog_heading">
        <Link to={`/${blog.id}`}>
          <h4>{blog?.title}</h4>
        </Link>
        </div>
        <p>{blog?.displayContent}</p>
        <div className="user">
          <div>Written By {blog.author.displayName}</div>
          <small>{blog.formattedDate}</small>
        </div>
      </div>
    </div>
  );
};

export default Blog;