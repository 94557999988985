import { configureStore } from "@reduxjs/toolkit";
import blogs from "./slices/blogsSlice";
import blog from "./slices/blogSlice";

const store = configureStore({
  reducer: {
    blogs,
    blog,
  },
});

export default store;
